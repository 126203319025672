<template>
  <div class="container">
    <div class="header">
      <van-icon
        v-if="data.orderStatus === 1 || data.orderStatus === 2"
        name="passed"
      />
      <van-icon v-else name="close" />
      <h4>
        {{ data.currencyType }}
        {{ data.orderPrice && data.orderPrice.toFixed(2) }}
      </h4>
      <p>{{ data.orderStatus | filterStatus }}</p>
    </div>
    <van-cell-group style="margin: 40px">
      <van-cell title="Client order number" :value="data.clientOrderNo" />
      <van-cell title="Brand order number" :value="data.brandOrderNo" />
      <van-cell
        title="Order amount"
        :value="data.currencyType + ' ' + data.orderPrice"
      />
      <van-cell title="Order finish time" :value="data.orderFinishTime" />
    </van-cell-group>

    <van-button
      v-if="data.orderStatus !== 0"
      type="primary"
      block
      @click="handleDone"
    >
      Done
    </van-button>
  </div>
</template>

<script>
import { openGet } from '@/apis'
import { isMobile } from '@/utils'

export default {
  name: 'Confirmation',
  filters: {
    filterStatus(status) {
      if (status === 0) {
        return 'Pending payment'
      } else if (status === 1) {
        return 'Payment successful!'
      } else if (status === 2) {
        return 'Pending successful!'
      } else if (status === 3) {
        return 'Payment failed'
      } else if (status === 4) {
        return 'Payment closed'
      } else {
        return 'Unknow status'
      }
    },
  },
  data() {
    return {
      data: {
        currencyType: '',
        orderPrice: '',
      },
    }
  },
  created() {
    if (isMobile) {
      this.$router.replace('/dummytools/m/result')
      return
    }
    this.getConsultPayment()
  },
  methods: {
    getConsultPayment() {
      const clientOrderNo = this.$route.query.clientOrderNo
      // localStorage.getItem('clientOrderNo') || ''
      openGet({ clientOrderNo }).then((data) => {
        this.data = data
      })
    },
    handleDone() {
      if (this.data.orderStatus === 1 || this.data.orderStatus === 2) {
        this.$router.push('/success')
      } else {
        this.$router.push('/fail')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 1080px;
  margin: 60px auto;
  padding: 40px 0;
  background: #fff;
}
.header {
  text-align: center;
  .van-icon {
    font-size: 60px;
    color: #010101;
  }
  h4 {
    margin: 10px 0;
    font-size: 28px;
  }
}
.van-button {
  width: 220px;
  margin: 40px auto;
}
.van-cell__value {
  font-weight: 600;
  color: #010101;
}
</style>
